.back-container {
  display: flex;
  gap: 21px;
  align-items: center;

  p {
    margin: 0;
    font-family: 'Mabry-Bold';
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.02em;

    @media (min-width: $bp-small) {
      font-size: 24px;
    }
  }
}

.confirm-transaction-wrapper {
  display: flex;
  flex-direction: column;
  gap: 35px;
  position: relative;

  .transaction-top-detail {
    display: flex;
    width: 239px;
    box-shadow: 0px 4px 48px $shadow-color;
    border-radius: 40px;
    padding: 12px 16px;
    background: $color-white;
    justify-content: space-between;
    margin: 0 auto;

    .token-container {
      display: flex;
      gap: 9px;
      align-items: center;

      p {
        margin: 0;
        text-transform: uppercase;
        font-family: 'Mabry-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 140%;
        letter-spacing: -0.04em;
      }
    }

    .img-container {
      display: flex;

      img {
        transform: rotate(90deg);
      }
    }
  }

  .transaction-table {
    display: flex;
    flex-direction: column;

    .bold-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: $border;
      padding: 8px 0;
      height: 60px;

      p {
        margin: 0;
        font-size: 15px;
        line-height: 140%;
        text-align: center;
        letter-spacing: -0.02em;
      }

      h6 {
        display: flex;
        font-family: 'Mabry-Bold';
        font-size: 24px;
        line-height: 140%;
        letter-spacing: -0.02em;

        display: flex;
        align-items: baseline;
        gap: 5px;

        span {
          font-size: 13px;
          line-height: 140%;
          text-align: center;
          letter-spacing: -0.02em;
          color: $cool-grey;
          text-transform: uppercase;
        }
      }
    }

    .light-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 55px;
      border-bottom: $border;

      .title {
        font-size: 15px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: $cool-grey;
      }

      .detail {
        font-size: 15px;
        line-height: 140%;
        text-align: right;
        letter-spacing: -0.02em;
        color: $color-black;
        text-transform: uppercase;
      }
    }

    .medium-list {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 12px;

      .title {
        font-size: 15px;
        line-height: 140%;
        letter-spacing: -0.02em;
      }

      .address {
        font-family: 'Mabry-Medium';
        height: 34px;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 100%;
        text-align: right;

        @media (max-width: $bp-small) {
          max-width: 181px;
          letter-spacing: -0.02em;
          overflow-wrap: break-word;
        }
      }
    }
  }

  .lower-details {
    display: flex;
    flex-direction: column;

    .info-container {
      display: flex;
      gap: 18px;
      align-items: center;
      background: $light-purple;
      border-radius: 12px;
      padding: 16px 18px;
      margin-bottom: 24px;

      p {
        font-family: 'Mabry-Medium';
        font-size: 15px;
        line-height: 140%;
        letter-spacing: -0.04em;
      }
    }
    button {
      margin-top: 0;

      &:last-child {
        margin-top: 12px;
      }
    }
  }
}

.confirm-account-container {
  width: 100%;
  max-width: 335px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  .confirm-account-text {
    width: 100%;
    max-width: 335px;
    font-size: 17px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.02em;
    padding: 16px 0;
    border-bottom: $border;
  }

  .user-icon-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    border-radius: 42px;
    margin: 24px 0;
    background: linear-gradient(to bottom, $off-ramp-primary, $color-grey);

    p {
      font-size: 36px;
      text-align: center;
      letter-spacing: -0.02em;
    }
  }

  .name {
    font-size: 17px;
    line-height: 140%;
    letter-spacing: -0.02em;
  }

  .account-details {
    font-size: 15px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: $cool-grey;

    display: flex;
    gap: 8px;
    align-items: center;

    span {
      margin-top: -2px;
    }
  }
}

.button-container {
  margin-top: 64px;

  button {
    max-width: 335px;
    margin: 0 auto;
  }
}

.onAndOffRampError {
  .fee-price-wrapper {
    padding-top: 7.5px !important;
    border-radius: 12px;
    margin-top: 16px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modall {
  background-color: #000;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  min-height: 200px !important;
  min-width: 300px;
  color: #fff;

  p {
    color: white;
  }
}

.modal-close {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #ccc;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-close:hover {
  background-color: #aaa;
}

.modal-content {
  min-height: 200px !important;
  min-width: 300px;
}
