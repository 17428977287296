@import url('../font/stylesheet.css');

// Color Declaration

$color-black: #191c1f;
$color-white: #ffffff;
$color-grey: #eceff1;
$dark-grey: #202529;
$light-grey-L001: #f3f5f7;
$light-grey-L002: #eceff1;
$light-grey-L003: #d9dee4;
$light-grey-L004: #b2bbc6;
$cool-grey: #8898aa;
$color-fringe: #3385e2;
$border: 1px solid $color-grey;
$color-green-3: #6fcf97;
$color-green-2: #27ae60;
$color-red-100: #fee3e2;
$color-red: #eb5757;
$color-red-500: #eb5757;
$off-ramp-primary: #946cff;
$light-green: rgba(111, 207, 151, 0.25);
$file-border: #cae8fc;
$light-purple: rgba(148, 108, 255, 0.15);
$shadow-color: rgba(178, 187, 198, 0.35);
$light-grey-L01: #f3f5f6;
$network-background: #ffe8a4;
$light-purple-01: rgba(151, 71, 255, 0.1);
$light-green-01: rgba(39, 174, 96, 0.1);
$color-blue: #206fc9;
$transparent-bg: rgba(0, 0, 0, 0.7);

// Font
$font-large: 40px;
$font-medium: 24px;
$font-small: 14px;

// Media Screen
$bp-x-largest: 87.5em; //1400px
$bp-largest: 75em; //1200px
$bp-bit-large: 64em; //1024px
$bp-large: 60.75em; //972x
$bp-medium: 56.25em; //900px
$bp-small: 37.5em; //600px
$bp-smallest: 31.25em; //500px
$bp-x-small: 20em; //320px
$bp-xx-small: 17.5em; //280px

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    color: $color-black;
  }
}

body {
  margin: 0;
  font-family: 'Mabry', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-grey !important;
  font-size: $font-small !important;
  color: $color-black !important;
}

.cursor {
  cursor: pointer;
}
