.logo-container {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-white;
  box-shadow: inset 0px -2px 3px rgba(206, 207, 245, 0.7);
  border-radius: 16px;
}

.landing-page {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  padding: 16px 16px 24px;

  .inner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    position: relative;
    padding: 100px 0;
    background: #f3f5f7;
    overflow: hidden;
    min-height: 95vh;
    max-height: 1011px;
    max-width: 587px;
    margin: 0 auto;
    box-shadow: 5px 4px 48px $shadow-color;

    .top-bg {
      position: absolute;
      top: 0;
    }

    .text-container {
      display: flex;
      flex-direction: column;
      gap: 4px;
      text-align: center;
      width: 241px;

      h5 {
        font-family: 'Mabry-Bold';
        font-size: 20px;
        line-height: 140%;
        text-align: center;
        letter-spacing: -0.02em;
      }

      p {
        font-size: 13px;
        line-height: 140%;
        text-align: center;
        width: 200px;
        margin: 0 auto;
        letter-spacing: -0.02em;
      }
    }

    .btn-control {
      display: flex;
      gap: 16px;
      justify-content: center;
      padding-bottom: 80px;

      img {
        box-shadow: 0px 4px 48px $shadow-color;
      }

      p {
        font-size: 15px;
        line-height: 140%;
        text-align: center;
        margin-top: 16px;
        font-family: 'Mabry-Medium';
        color: $cool-grey;
      }
    }
  }

  @media (min-width: $bp-small) {
    padding: 14px 14px 46px;
    max-height: 100vh;

    .inner {
      min-height: 95vh;
      max-height: 1011px;
      margin: 0 auto;

      .page-footer {
        margin: 0 32px;
      }
    }
  }
}
