.top-bar {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 6px;

  p {
    font-size: 13px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: $cool-grey;
    display: flex;
    align-items: center;
    gap: 4px;

    span {
      color: $color-black;
    }
  }
}

.verify-email-content {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 335px;
  margin: 0 auto;

  .text-field {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;

    @media (min-width: $bp-small) {
      max-width: 311px;
      margin: 16px auto;
    }
  }

  button {
    width: 100%;
    max-width: 100%;
    margin: 16px auto;

    @media (min-width: $bp-small) {
      max-width: 311px;
      margin: 16px auto;
    }
  }

  .fee-price-wrapper {
    margin: 0;
    padding: 7.5px;
    border-radius: 8px;
    margin-top: 8px;

    @media (min-width: $bp-small) {
      width: 311px;
      margin: 8px auto;
    }
  }
}

.invalid-email {
  img.logo-bg {
    transform: rotate(0deg);
    margin-top: -25px;
  }

  .welcome-message {
    margin-top: -50px;
  }
}

.resend-code {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 26px;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 4px;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: space-between;
}

.code-timer {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  line-height: 18.2px;
  color: $cool-grey;
}

.timer-out {
  color: $color-red;
}

.resend {
  font-size: 13px;
  line-height: 140%;
  letter-spacing: -0.26px;
  font-family: 'Mabry-Medium';
  color: $color-blue;
}
