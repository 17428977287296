.welcome-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .loading-container {
    margin-top: -10px;
  }
}

.top-welcome-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 13px;

  .welcome-message {
    font-size: 17px;
    line-height: 140%;
    letter-spacing: -0.02em;
    width: 223px;
    text-align: center;
    margin-top: -60px;
  }

  p {
    font-size: 13px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: $cool-grey;
  }
}

.welcome-page-logo-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;

  .logo-bg {
    transform: rotate(-135deg);
  }

  .logo-container {
    position: absolute;
    top: 45px;

    .float-icon {
      position: absolute;
      right: 17px;
      top: 23px;
    }
  }

  .response-icon-bg {
    position: absolute;
    top: 65px;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-white;
    box-shadow: inset 0px -2px 3px #efdff6;
    border-radius: 56px;
  }
}

.ramp-main {
  .onramp-top-bar {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-bottom: 16px;
    border-bottom: $border;

    .top-bar-title {
      margin: 0;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.04em;
      font-family: 'Mabry-Medium';
    }

    .top-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      box-sizing: border-box;

      .top-bar-user-details {
        display: flex;
        flex-direction: column;
        text-align: right;
        gap: 2px;

        .user-id {
          margin: 0;
          font-size: 13px;
          line-height: 140%;
          text-align: right;
          letter-spacing: -0.02em;
          color: $cool-grey;
        }

        .onramp-active {
          margin: 0;
          font-size: 13px;
          line-height: 140%;
          letter-spacing: -0.02em;
          color: $color-black;
          display: flex;
          gap: 6px;
          align-items: center;
          justify-content: flex-end;

          span {
            margin: 0;
            width: 9px;
            height: 9px;
            display: flex;
            align-items: center;

            img {
              width: 100%;
              margin-bottom: -1px;
            }
          }
        }
      }
    }
  }

  .form-control {
    height: 100%;
    border: 0;
    padding: 0;

    button {
      margin-top: 24px;
    }
  }
}
