.stablepay {
  display: flex;
  padding-top: 6rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .image {
    width: 48px;
    height: 48px;
    margin-bottom: 3rem;
  }

  h3 {
    font-family: 'Mabry-Medium';
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #1d2939;
    padding-right: 8rem;
    padding-left: 8rem;
    margin-bottom: 20px;

    @media (max-width: $bp-small) {
      padding-right: 3rem;
      padding-left: 3rem;
    }
  }
}
