.transfer-info {
  margin: 0 auto;
  position: relative;

  .top-page-bg {
    width: 120%;
    height: 130px;
    margin-top: -24px;
    margin-left: -34px;
    margin-right: -24px;
  }

  .account-number {
    font-family: 'Mabry-Bold';
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.02em;
    display: flex;
    gap: 9.67px;
    align-items: center;
  }

  .page-title {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    align-items: center;
    margin-top: -70px;

    p {
      font-size: 15px;
      line-height: 140%;
      text-align: center;
      letter-spacing: -0.02em;
    }

    h5 {
      font-family: 'Mabry-Bold';
      font-size: 32px;
      line-height: 100%;
      letter-spacing: -0.02em;
      display: flex;
      gap: 5px;
      align-items: baseline;

      span {
        font-size: 17px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.02em;
        color: $cool-grey;
      }

      .copy-icon {
        margin-left: 4.5px;
      }

      .copied-style {
        margin-left: 4.5px;
      }
    }
  }

  .transfer-into-title {
    font-family: 'Mabry-Medium';
    font-size: 15px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.04em;
  }

  .transfer-into {
    width: 100%;
    max-width: 339px;
    margin: 0 auto;
    // height: 124px;
    background: $color-grey;
    border: $border;
    border-radius: 12px;
    padding: 9px 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 32px;

    .account-container {
      display: flex;
      flex-direction: column;
      gap: 2px;
      min-height: 80px;
      height: 100%;
      width: 100%;
      align-items: center;
      background: $color-white;
      margin: 0 auto;
      border-radius: 12px;
      justify-content: center;

      p {
        font-size: 13px;
        line-height: 140%;
        letter-spacing: -0.02em;
      }

      .bank-details {
        align-items: center;
        justify-content: center;
      }
    }

    .wallet-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      height: 100%;
      width: 100%;
      background: $color-white;
      margin: 0 auto;
      border-radius: 12px;
      padding: 16px;

      .wallet-flex {
        display: flex;
        justify-content: space-between;
      }

      .wallet-address {
        font-family: 'Mabry-Medium';
        width: 239px;
        height: 34px;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 100%;
        text-align: left;
        letter-spacing: -0.02em;
        overflow-wrap: break-word;
      }

      p {
        font-size: 15px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: $cool-grey;
      }
    }
  }

  .amount-to-receive {
    width: 100%;
    max-width: 339px;
    height: 82px;
    background: $color-white;
    border: $border;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 16px;

    p {
      font-family: 'Mabry-Medium';
      font-size: 13px;
      line-height: 140%;
      letter-spacing: -0.02em;
      text-transform: uppercase;
    }

    .amount-flex {
      display: flex;
      gap: 4px;
      align-items: flex-end;
    }

    h6 {
      font-family: 'Mabry-Bold';
      font-size: 20px;
      line-height: 140%;
      letter-spacing: -0.02em;
      display: flex;
      gap: 9.67px;
    }

    span {
      font-size: 15px;
      line-height: 140%;
      letter-spacing: -0.02em;
      color: $cool-grey;
      font-family: 'Mabry-Bold';
      margin-bottom: 1.5px;
    }
  }

  .ussd-container {
    width: 100%;
    max-width: 339px;
    height: 82px;
    background: $color-white;
    border: $border;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 16px;

    p {
      font-family: 'Mabry-Bold';
      font-size: 13px;
      line-height: 140%;
      letter-spacing: -0.02em;
    }

    h6 {
      font-family: 'Mabry';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      letter-spacing: -0.02em;
      color: $color-green-2;
      display: flex;
      gap: 9.67px;
    }
  }

  .count-down {
    text-align: center;
    font-size: 15px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: $cool-grey;
    margin: 24px auto;
  }

  .payment-received-count-down {
    text-align: center;
    font-size: 18px !important;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: $color-red !important;
    margin: 24px auto;
  }

  .info-frame {
    display: flex;
    align-items: center;
    gap: 18px;
    padding: 16px 18px;
    gap: 16px;
    width: 100%;
    max-width: 339px;
    margin: 0 auto;
    height: fit-content;
    background: $light-green-01;
    border-radius: 12px;

    p {
      font-family: 'Mabry-Bold';
      font-size: 15px;
      line-height: 140%;
      letter-spacing: -0.04em;
    }
  }

  button {
    width: 100%;
    max-width: 339px;
    margin: 16px auto;
  }
}

.send-crypto {
  .page-title {
    .copied-style {
      background: $light-purple-01 !important;

      .copied-text {
        color: $off-ramp-primary !important;
      }
    }
  }

  .left-align {
    max-width: 462px;
    text-align: left;
  }

  .info-frame {
    width: 100%;
    max-width: 462px;
    background: $light-purple-01;

    p {
      font-size: 13px;
    }
  }

  .count-down {
    max-width: 462px;
    text-align: left;
  }

  button {
    width: 100%;
    max-width: 462px;
  }

  .wallet-container {
    width: 100%;
    max-width: 462px;
    margin: 0 auto;
    height: fit-content;
    background: $color-grey;
    border: $border;
    border-radius: 12px;
    padding: 9px 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 32px;
    overflow: hidden;

    .flex-details {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .inner-flex {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 100%;
      }

      .qr-container {
        height: 100%;
        margin: 0 auto;
        width: 100%;
        max-width: 100px;
        border: 1.5px solid $color-black;
        padding: 6px;
        border-radius: 7px;
      }
    }

    .address-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      background: $color-white;
      padding: 16px;
      border-radius: 12px;
      box-sizing: border-box;

      .inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        min-width: 100%;
        max-width: 100%;
        box-sizing: border-box;

        .copied-style {
          background: $light-purple-01 !important;

          .copied-text {
            color: $off-ramp-primary !important;
          }
        }
      }

      .inner-title {
        font-size: 15px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: $cool-grey;
      }

      .wallet-flex {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: space-between;
        box-sizing: border-box;

        .wallet-address {
          font-family: 'Mabry-Medium';
          max-width: 190px;
          font-style: normal;
          font-weight: 500;
          font-size: 17px;
          line-height: 100%;
          text-align: left;
          letter-spacing: -0.02em;
          overflow-wrap: break-word;

          @media (min-width: $bp-small) {
            max-width: 340px;
          }
        }

        .token-network-flex {
          display: flex;
          gap: 8px;

          h6 {
            font-family: 'Mabry-Medium';
            font-size: 17px;
            line-height: 100%;
            letter-spacing: -0.02em;
          }

          span {
            background: $network-background;
            border-radius: 2px;
            padding: 1px 5px;
            font-size: 10px;
            line-height: 140%;
            letter-spacing: -0.02em;
          }
        }
      }
    }
  }
}

.success-transaction {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 416px;
  margin: 0 auto;

  .description {
    font-size: 13px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.02em;
    color: $cool-grey;
    margin: 0 auto;
    margin-top: 8px;
  }

  button {
    width: 113px;
    margin: 16px auto 0;
  }

  .footer-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 45.5px;
      margin: 32px auto 24px;
    }

    .email {
      font-size: 13px;
      line-height: 140%;
      text-align: center;
      letter-spacing: -0.02em;
      color: $cool-grey;
      width: 230px;

      a {
        text-decoration: none;
        font-family: 'Mabry-Bold';
        color: $color-black;
      }
    }
  }
}

.partner-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
  margin: 16px auto;
}
