.basic-kyc {
  height: fit-content;
  height: 100%;
  .top-bar {
    padding-bottom: 17px;
  }

  .page-title {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 0;
    border-top: $border;

    .title {
      font-family: 'Mabry-Bold';
      font-size: 20px;
      line-height: 140%;
      letter-spacing: -0.02em;
    }

    .description {
      font-size: 13px;
      line-height: 140%;
      letter-spacing: -0.02em;
      color: $cool-grey;
    }

    .privacy-container {
      padding: 10px;
      display: flex;
      gap: 12px;
      width: 100%;
      background: $light-grey-L002;
      border-radius: 8px;
      margin-top: 2px;

      @media (min-width: $bp-small) {
        margin-top: 12px;
      }

      p {
        font-size: 13px;
        line-height: 150%;
        letter-spacing: -0.02em;
      }
    }
  }

  .form-control {
    padding: 0;
    border: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;

    .kyc-title {
      font-size: 13px;
      line-height: 150%;
      letter-spacing: -0.02em;
      color: $cool-grey;
      padding-top: 12px;
      border-top: $border;
    }

    .field-container {
      padding: 0;
    }

    .select-field-container {
      padding: 0;
    }

    button {
      margin: 0;
    }
  }

  .data-modal-id {
    padding-bottom: 2rem;
  }

  .fee-price-wrapper {
    margin: 0;
    padding: 7.5px;
    border-radius: 8px;
  }
}

.kyc-error {
  margin-bottom: 30px;
  .fee-price-wrapper {
    margin: 0;
    padding: 7.5px;
    border-radius: 8px;
  }

  button {
    width: 113px;
    height: 38px;
    margin: 16px auto;
  }
}

.hidden {
  display: none !important;
}

.tier-error-container {
  width: 311px;
}
