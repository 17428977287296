.add-bank-container {
  display: flex;
  gap: 12px;
  margin-top: 16px;
  align-items: center;
  cursor: pointer;

  .img-container {
    width: 32px;
    height: 32px;
    padding: 4px;
    background: $light-grey-L01;
    border-radius: 100px;
    display: flex;
    align-items: center;
  }

  p {
    font-size: 15px;
  }
}

.banks-list {
  flex: 1;
  margin-top: 16px;
  border-top: $border;
  height: calc(95vh - 330px);
  overflow-y: scroll;

  .bank {
    padding: 12px 0 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: $border;
    cursor: pointer;

    .top-details {
      display: flex;
      align-items: center;
      gap: 4px;

      p {
        font-size: 15px;
        letter-spacing: -0.3px;

        @media (max-width: $bp-small) {
          font-size: 12px;
        }
      }

      img {
        margin-top: -5px;
      }
    }

    .name {
      font-size: 13px;
      color: $cool-grey;
      letter-spacing: -0.26px;
    }

    .chevron-container {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        transform: rotate(-90deg);
      }
    }
  }
}
