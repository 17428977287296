@import 'base';
@import './Fields/fields';
@import './Button/button';

@import './LandingPage/landing-page';
@import './Onramp/onramp';
@import './ConfirmTransaction/confirm-transaction';
@import './VerifyEmail/verify-email';
@import './TransferInfo/transfer-info';
@import './Kyc/kyc';
@import './ReceivingBanks/receiving_banks';
@import './StablePay//stablepay';

.custom-layout {
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 16px;
  padding-bottom: 32px !important;

  img {
    cursor: pointer;
  }

  .container {
    position: relative;
    width: 100%;
    min-height: 95vh;
    height: fit-content;
    max-width: 587px;
    background: $color-white;
    padding: 24px 16px;
    box-shadow: 0px 4px 48px $shadow-color;
    // overflow-y: scroll;
    overflow-x: hidden;

    .children-container {
      padding-bottom: 90px;
      min-height: calc(95vh - 48px);
    }
  }

  @media (min-width: $bp-small) {
    padding: 14px 0 46px;

    .container {
      padding: 24px 32px;
      margin: 0 auto;

      .page-footer {
        // width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0 32px;
      }
    }
  }
}

.page-footer {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  height: 52px;
  display: flex;
  padding: 17px 0;
  justify-content: center;
  align-items: center;
  border-top: $border;
  margin: 0 16px;
  display: flex;
  justify-content: space-between;

  .font-text {
    font-family: 'Mabry-Medium';
  }

  .footer-link {
    text-decoration: underline;
    color: $color-fringe;
    text-underline-offset: 2px;
  }

  .footer-info {
    margin: 0;
    display: flex;
    align-items: center;
    color: $cool-grey;

    span {
      margin-left: 5px;
      display: flex;
      align-items: center;
      gap: 1.61px;
    }
  }
}

.copy-icon {
  margin-top: -3px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  margin-top: 20px;

  .dot {
    width: 8px;
    height: 8px;
    margin: 0 5px;
    background-color: $color-black;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out;
  }

  .dot:nth-child(1) {
    animation-delay: -0.32s;
  }

  .dot:nth-child(2) {
    animation-delay: -0.16s;
  }

  @keyframes bounce {
    0%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
  }
}

.copied-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 8px;
  gap: 4px;
  width: 73px;
  height: 24px;
  background: $light-green-01;
  border-radius: 16px;

  .copied-text {
    font-family: 'Mabry-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 13px !important;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: $color-green-2;
  }
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $cool-grey;
  font-family: 'Mabry-Medium';
  font-size: 17px;
  line-height: 100%;
  letter-spacing: -0.02em;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $cool-grey;
  font-family: 'Mabry-Medium';
  font-size: 17px;
  line-height: 100%;
  letter-spacing: -0.02em;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $cool-grey;
  font-family: 'Mabry-Medium';
  font-size: 17px;
  line-height: 100%;
  letter-spacing: -0.02em;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $cool-grey;
  font-family: 'Mabry-Medium';
  font-size: 17px;
  line-height: 100%;
  letter-spacing: -0.02em;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.landing-page-error-container {
  max-width: 450px;
  margin: 0 auto;
  padding: 12px 16px;
  background: $color-white;
  display: flex;
  flex-direction: column;
  border: $border;
  border-radius: 8px;
  margin: 0 20px;

  @media screen and (min-width: $bp-small) {
    padding: 16px 20px;
  }

  .container-title {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;

    img {
      width: 36px;
      @media screen and (min-width: $bp-small) {
        width: 40px;
      }
    }

    h2 {
      font-family: 'Mabry-Medium';
      font-weight: 500;
      color: $dark-grey;
      font-size: 15px;

      @media screen and (min-width: $bp-small) {
        font-size: 17px;
      }
    }
  }

  .landing-page-error {
    font-size: 13px;
    color: $dark-grey;

    @media screen and (min-width: $bp-small) {
      font-size: 15px;
    }
  }
}
.solana-pay-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: azure;
  height: 100vh;
}

.solana-pay-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.solana-pay-logo {
  margin: 0px 8px -4px 0;
}

.pending-state {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $transparent-bg;
  bottom: 0;
  z-index: 999999999999999999;

  p {
    color: #e4e7ec;
    font-size: 14px;
    font-weight: 500;

    @media screen and (min-width: $bp-small) {
      font-size: 20px;
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50px;
    justify-content: space-between;
  }
}
