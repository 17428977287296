/* Mabry font */
@font-face {
  font-family: 'Mabry';
  src: url('Mabry-Regular.eot');
  src: local('Mabry Medium'), local('Mabry-Regular'),
    url('Mabry-Regular.eot?#iefix') format('embedded-opentype'),
    url('Mabry-Regular.woff2') format('woff2'),
    url('Mabry-Regular.woff') format('woff'),
    url('Mabry-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mabry-Medium';
  src: url('Mabry-Medium.eot');
  src: local('Mabry Medium'), local('Mabry-Medium'),
    url('Mabry-Medium.eot?#iefix') format('embedded-opentype'),
    url('Mabry-Medium.woff2') format('woff2'),
    url('Mabry-Medium.woff') format('woff'),
    url('Mabry-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Mabry-Bold';
  src: url('Mabry-Bold.eot');
  src: local('Mabry Bold'), local('Mabry-Bold'),
    url('Mabry-Bold.eot?#iefix') format('embedded-opentype'),
    url('Mabry-Bold.woff2') format('woff2'),
    url('Mabry-Bold.woff') format('woff'),
    url('Mabry-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
