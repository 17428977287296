input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.rotate {
  transform: rotate(-180deg);
  transition: 0.3s ease;
}

.chevron {
  transition: 0.3s ease;
}

.place-holder {
  margin: 0;
  font-size: 17px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: $cool-grey;
  font-family: 'Mabry-Medium';
}

.field-title {
  font-size: 15px;
  line-height: 140%;
  letter-spacing: -0.02em;
}

.token-icon {
  width: 32px !important;
  height: 32px !important;
}
.token-medium-icon {
  width: 24px !important;
  height: 24px !important;
}

.small-token-icon {
  width: 18px !important;
  height: 18px !important;
}

.upload-container {
  .file {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 140px;
    background: $light-grey-L002;
    border: 1px dashed $file-border;
    border-radius: 12px;
    margin: 0px 0;
    position: relative;
    cursor: pointer;

    img {
      cursor: pointer;
    }

    .preview--image {
      cursor: pointer;
      // background: $light-grey-L002;
      max-width: 140px;
      min-height: 140px;
      padding: 10px 30px;
      margin: 0 auto;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }

    input {
      position: absolute;
      top: 35px;
      width: 40px;
      height: 50px;
      cursor: pointer;
      border: none;
      opacity: 0;
      cursor: pointer;
    }

    p {
      margin: 0;
      font-size: 17px;
      line-height: 100%;
      letter-spacing: -0.02em;
      color: $cool-grey;
      font-family: 'Mabry-Medium';
    }

    span {
      margin: 0;
      font-size: 12px;
      line-height: 100%;
      letter-spacing: -0.02em;
      color: $cool-grey;
      font-family: 'Mabry';
    }
  }
}

input {
  font-family: 'Mabry-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;
  letter-spacing: -0.02em;
  width: 100%;
  box-sizing: border-box;
  background: $light-grey-L002;
  border-radius: 24px;
  outline: none;
  border: 0;

  &:focus {
    background: $light-grey-L003;
  }
}

.field-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: $border;
  cursor: pointer;

  .title {
    margin: 0;
    font-size: 17px;
    line-height: 140%;
    letter-spacing: -0.02em;
  }

  .in-active-field {
    color: $light-grey-L004 !important;
  }
}

.select-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  width: 100%;
  height: 48px;
  background: $color-grey;
  border-radius: 24px;
  cursor: pointer;

  .place-holder {
    margin: 0;
    font-size: 17px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: $cool-grey;
    font-family: 'Mabry-Medium';
  }

  .field-title-container {
    display: flex;
    gap: 8px;
    align-items: center;

    .img-container {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;

      img {
        padding: 0;
        width: 100%;
      }
    }
  }
}

.data-modal {
  position: absolute;
  background: $color-white;
  box-shadow: 0px 4px 48px rgba(178, 187, 198, 0.35);
  border-radius: 8px;
  padding: 0 12px;
  top: 89px;
  left: 0;
  right: 0;
  z-index: 100;
  height: fit-content;
  max-height: 300px;
  overflow: auto;
  opacity: 1;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  input {
    padding: 16px;
    gap: 16px;
    max-width: 491px;
    height: 56px;
    background: $light-grey-L002;
    border-radius: 8px;
    padding-left: 48px;
    margin-top: 16px;

    &:focus {
      background: $light-grey-L003;
    }

    @media (max-width: $bp-small) {
      height: 47px;
      border-radius: 24px;
    }
  }

  .search-icon {
    position: absolute;
    left: 0;
    top: 33px;
    left: 32px;

    @media (max-width: $bp-small) {
      top: 28px;
    }
  }

  .data-detail {
    display: flex;
    width: 100%;
    gap: 12px;
    align-items: center;
    padding: 12px 0;
    border-bottom: $border;
    height: 56px;
    opacity: 1;
    transition: 0.3s ease;

    &:last-child {
      border: 0;
    }

    img {
      width: 32px;
      height: 32px;
      opacity: 1;
      transition: 0.3s ease-in-out;
    }

    .type-details {
      display: flex;
      flex-direction: column;
      gap: 2px;
      opacity: 1;
      transition: 0.3s ease-in-out;

      .title {
        margin: 0;
        font-size: 15px;
        line-height: 140%;
        letter-spacing: -0.02em;
        opacity: 1;
        transition: 0.3s ease-in-out;
      }

      .network-list {
        display: flex;
        gap: 4px;
        opacity: 1;
        transition: 0.3s ease-in-out;
      }

      .network {
        margin: 0;
        font-size: 13px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: $cool-grey;
        text-transform: uppercase;
        opacity: 1;
        transition: 0.3s ease-in-out;

        &::after {
          content: ',';
        }

        &:last-child::after {
          content: '';
        }
      }
    }
  }
}

.close-data-modal {
  height: 0px !important;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease;

  .data-detail {
    height: 0px;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease;
  }
}

.select-field-container {
  width: 100%;
  display: flex;
  gap: 16px;
  padding-top: 16px;

  .active-position-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .img-container {
      height: 100%;
      display: flex;
      justify-content: center;

      img {
        height: 100%;
      }
    }

    .position {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      min-height: 24px;
      background: $color-green-2;
      border-radius: 16px;

      span {
        color: $color-white;
        margin: 0;
        font-size: 13px;
        line-height: 140%;
        font-family: 'Mabry-Bold';
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .un-active-position {
      background: $light-grey-L003;
    }

    .former-position {
      background: $cool-grey;
    }
  }
}

.fee-price-wrapper {
  margin-top: -17px;
  width: 100%;
  display: flex;
  padding: 22px 9.3px 7.5px;
  justify-content: space-between;
  background: $light-green;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  flex-direction: column;
  gap: 8px;

  @media (min-width: $bp-small) {
    flex-direction: row;
    justify-content: space-between;
  }

  .fee-container {
    display: flex;
    align-items: center;
    gap: 9.33px;

    p {
      margin: 0;
      font-size: 13px;
      line-height: 140%;
      letter-spacing: -0.02em;
      color: $color-green-2;
      font-family: 'Mabry-Bold';

      span {
        color: $color-black;
      }
    }
  }

  .total {
    margin: 0;
    font-size: 13px;
    line-height: 140%;
    letter-spacing: -0.02em;

    span {
      font-family: 'Mabry-Bold';
    }
  }
}

.upload-error-text {
  font-size: 15px;
  color: $color-red-500;
  line-height: 140%; /* 21px */
  letter-spacing: -0.3px;
}

.error-fee-price-wrapper {
  background: $color-red-100;

  .fee-container .error-message {
    color: $color-red-500;
  }
}

.error-field {
  background: $color-red-100;
  margin-top: 10px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 9.33px;
  border-radius: 12px;

  .error-message {
    color: $color-red-500;
  }
}

.amount-input-container {
  .input-field-no-border {
    border: 0;
  }

  .onRamp-success-field-border {
    border: 1px solid $color-green-2;
  }

  .offRamp-success-field-border {
    border: 1px solid $off-ramp-primary;
  }

  .inputs-container {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    padding: 0px 16px;
    height: 108px;
    border-radius: 8px;
    background: $light-grey-L002;
    box-sizing: border-box;

    .input-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 8px;

      input {
        height: 30px;
        font-size: 17px;
        color: $cool-grey;
        outline: none;
        padding-right: 50px;
        background: $light-grey-L002;
        border-radius: 0;
      }

      p {
        margin: 0;
        font-size: 10px;
        color: $cool-grey;
        line-height: 140%;
        letter-spacing: -0.02em;
        text-transform: uppercase;
      }
    }

    .top-container {
      p {
        color: $color-green-2;
      }

      .off-ramp-token {
        color: $off-ramp-primary;
      }

      input {
        color: $color-black !important;

        &::-webkit-input-placeholder {
          color: $color-black;
        }
        &::-moz-placeholder {
          color: $color-black;
        }
        &:-ms-input-placeholder {
          color: $color-black;
        }
        &:-moz-placeholder {
          color: $color-black;
        }
      }
    }

    .top-input-border {
      border-top: 1px solid $light-grey-L003;
    }

    .switch-container {
      position: absolute;
      top: 33px;
      right: 16px;
      background: $color-white;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 40px;
    }
  }

  .price-next-button-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .price-container {
      margin: 0;
      padding: 8px;
      gap: 8px;
      width: fit-content;
      height: 29px;
      background: $light-grey-L001;
      border-radius: 8px;
      display: flex;
      align-items: center;

      .rate {
        margin: 0;
        font-size: 13px;
        line-height: 140%;
        letter-spacing: -0.02em;
        display: flex;
        gap: 4px;

        .loading-container {
          margin-top: 8px;

          .dot {
            width: 4px;
            height: 4px;
            margin: 0 3px;
            background-color: $color-black;
            border-radius: 50%;
            animation: smallBounce 1.4s infinite ease-in-out;
          }

          .dot:nth-child(1) {
            animation-delay: -0.32s;
          }

          .dot:nth-child(2) {
            animation-delay: -0.16s;
          }

          @keyframes smallBounce {
            0%,
            80%,
            100% {
              transform: translateY(0);
            }
            40% {
              transform: translateY(-5px);
            }
          }
        }
      }
    }

    button {
      width: 90px;
      height: 37px;
      background: $cool-grey;
      border-radius: 40px;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-family: 'Mabry-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: $color-white;
      }
    }

    .active-btn {
      background: $dark-grey;
    }
  }
}

.wallet-address-wrapper {
  position: relative;

  input {
    height: 48px;
    padding: 0 100px 0 16px;
    color: $color-black;
  }

  .absolute-icons {
    position: absolute;
    top: 11px;
    right: 17px;
    display: flex;
    align-items: center;
    gap: 17px;

    span {
      padding: 4px 8px;
      width: 58px;
      height: 26px;
      background: $color-white;
      border-radius: 6px;
      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-family: 'Mabry-Bold';
        font-size: 13px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: $color-black;
        text-transform: uppercase;
      }
    }

    @media (max-width: $bp-small) {
      .web-scan {
        display: none;
      }
    }
  }
}

.error-amount-input-container {
  border: 2px solid $color-red-500 !important;
  background: $color-white !important;
}

.error-amount-input-border {
  border: 1px solid $color-red-500 !important;
}

.success-wallet-address {
  border: 2px solid $color-green-2 !important;
  background: $color-white !important;
}

.no-border {
  border: 0 !important;
}

.loading-spinner {
  position: absolute;
  right: 16px;
  top: 73px;
  width: 20px;
  height: 20px;
  border: 3px solid $cool-grey;
  border-top: 3px solid $color-black;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.amount-spinner-container {
  .loading-spinner {
    position: static;
    height: 15px;
    width: 15px;
    margin-top: 2px;
  }
}

.text-field {
  position: relative;

  input {
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    background: $light-grey-L002;
    border-radius: 24px;
    padding: 16px 18px 16px 50px;
    font-size: 17px;
    color: $color-black;
    line-height: 140%;
    letter-spacing: -0.02em;
    outline: none;
    border: 0;

    &:focus {
      background: $light-grey-L003;
    }
  }

  img {
    position: absolute;
    top: 15px;
    left: 18px;
  }
}

.text-field-container {
  position: relative;
  padding-top: 16px;

  p {
    font-size: 17px;
    line-height: 140%;
    letter-spacing: -0.02em;
    margin-bottom: 16px;
  }

  input {
    padding: 16px 90px 16px 18px;
    height: 56px;
    color: $color-black !important;
    font-family: 'Mabry-Medium';
    font-size: 17px;
    line-height: 100%;
    letter-spacing: -0.02em;
  }

  button {
    background: transparent;
  }

  .check-btn {
    position: absolute;
    right: 16px;
    top: 68px;
    background: none;
    border: 0;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .span-btn {
    position: absolute;
    right: 16px;
    top: 47px;
    padding: 4px 8px;
    width: fit-content;
    height: 26px;
    background: $light-grey-L002;
    border: 0;
    border-radius: 12px;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: space-between;

    .check-icon {
      position: static;
    }

    span {
      font-family: 'Mabry-Bold';
      font-size: 13px;
      line-height: 140%;
      letter-spacing: -0.02em;
      color: $color-black;
    }
  }

  .error-container {
    display: flex;
    gap: 11.55px;
    align-items: center;
    padding: 9.5px 0 0 16px;

    img {
      position: static;
    }

    p {
      margin: 0;
      font-size: 15px;
      line-height: 140%;
      letter-spacing: -0.02em;
      color: $color-red;
    }
  }
}

.select-bank-field-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
  cursor: pointer;

  .title {
    font-size: 17px;
    line-height: 140%;
    letter-spacing: -0.02em;
  }

  .field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    max-width: 523px;
    height: 56px;
    background: $light-grey-L002;
    border-radius: 24px;

    .field-title {
      font-family: 'Mabry-Medium';
      font-size: 17px;
      line-height: 100%;
      letter-spacing: -0.02em;
      color: $color-black;
    }
  }

  .list-modal {
    position: absolute;
    top: 113px;
    left: 0;
    right: 0;
    max-width: 523px;
    height: 383px;
    padding: 16px;
    background: $color-white;
    box-shadow: 0px 4px 48px $shadow-color;
    border-radius: 12px;
    transition: 0.3s ease-in-out;
    z-index: 100;
    overflow: hidden;

    input {
      padding: 16px;
      gap: 16px;
      max-width: 491px;
      height: 56px;
      background: $light-grey-L002;
      border-radius: 8px;
      padding-left: 48px;

      &:focus {
        background: $light-grey-L003;
      }

      @media (max-width: $bp-small) {
        height: 47px;
        border-radius: 24px;
      }
    }

    .search-icon {
      position: absolute;
      left: 0;
      top: 33px;
      left: 32px;

      @media (max-width: $bp-small) {
        top: 28px;
      }
    }

    .search-title {
      font-size: 15px;
      line-height: 100%;
      letter-spacing: 0.15em;
      color: $cool-grey;
      padding: 16px 0;
      margin-top: 8px;
    }

    .list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 0;
      border-bottom: $border;

      &:last-child {
        border: 0;
      }

      img {
        width: 14.05px;
        height: 10.22px;
      }

      p {
        font-size: 17px;
        line-height: 140%;
        letter-spacing: -0.02em;
      }
    }

    .list-container {
      height: 220px;
      overflow: scroll;
      padding-bottom: 30px;

      .loading-container {
        margin-top: 100px;
      }
    }

    .bottom-blur {
      height: 40px;
      top: 345px;
      left: 0;
      right: 0;
      margin: -25px;
      filter: blur(12px);
      background: $color-white;
    }
  }
}

.active-select-field {
  background: $light-grey-L003 !important;
}

.close-data-modal {
  height: 0px !important;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease;

  .data-detail {
    height: 0px;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease;
  }
}

.date-container {
  position: relative;

  .hide-img {
    display: none;
  }

  .img {
    position: absolute;
    top: 12px;
    right: 16px;
  }
}

.input-field {
  padding: 16px;
  height: 48px;
  font-size: 17px;
  font-family: 'Mabry-Medium';
}

.select {
  position: relative;

  .field-title {
    font-family: 'Mabry-Medium';
  }

  .data-modal {
    top: 48px;
  }
}

.date-false-container {
  position: relative;
  cursor: pointer;

  img {
    position: absolute;
    top: 12px;
    right: 16px;
  }
}

.date-field {
  width: 100%;
  height: 48px;
  border: none !important;
  background: $light-grey-L002;
  border-radius: 32px;
  padding-left: 16px;
  padding-right: 16px;
  align-items: 'center';

  .react-date-picker__button:enabled {
    cursor: pointer;
    max-width: 30px;
    margin-bottom: 20px;
  }

  .react-date-picker__button svg {
    max-width: 30px;
    margin-top: -5px;
    display: none;
  }

  input {
    width: 50%;
    font-size: 15px;
    font-family: 'Mabry-Medium';
    justify-content: 'space-between';
  }
}

.react-date-picker__wrapper {
  border: none !important;
  cursor: pointer;
}

.bvn-wrapper {
  @media (min-width: $bp-small) {
    width: 311px;
    margin: 0 auto;
  }
}

.phone-number-wrapper {
  background: $light-grey-L002;
  border-radius: 24px;
  height: 48px;
  width: 100%;
  padding: 16px 0 16px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;

  @media (min-width: $bp-small) {
    width: 311px;
    margin: 0 auto;
  }

  .data-modal {
    position: absolute;
    top: 48px;
  }

  .flag-code-container {
    display: flex;
    gap: 12px;
    align-items: center;

    .flag-wrapper {
      display: flex;
      gap: 6px;
      align-items: center;

      .flag {
        width: 20px;
        height: 20px;
      }

      .chevron {
        color: $cool-grey;
        margin-top: 2px;
      }
    }

    p {
      font-family: 'Mabry-Medium';
      font-size: 17px;
      line-height: 100%;
      letter-spacing: -0.02em;
    }
  }

  input {
    padding: 0;

    &:focus {
      background: none;
    }
  }
}

.otp-input {
  width: 30px;
  height: 30px;
}
