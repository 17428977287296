.primary-button {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  gap: 6px;
  width: 100%;
  height: 49px;
  background: $cool-grey;
  opacity: 0.5;
  border: 0;
  border-radius: 72px;
  cursor: pointer;

  span {
    font-family: 'Mabry-Medium' !important;
    font-size: 17px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: $color-white;
  }
}

.enabled-primary-button {
  background: $color-black;
  opacity: 1;
}

.wallet-adapter-button {
  // margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 20px !important;
  gap: 6px !important;
  width: 100% !important;
  height: 49px;
  background: $color-black !important;
  // opacity: 0.5;
  color: #fff !important;
  border: 0;
  border-radius: 72px !important;
  cursor: pointer;
}

.wallet-adapter-button-trigger {
  background: $color-black !important;
  min-width: 256px !important;
}

.group-button {
  margin-top: 12px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 16px;
}
